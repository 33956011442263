import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SportService } from 'app/services/sport/sport.service';
import { PrincipalService } from 'app/services/auth/principal.service';
import { PermissionService } from 'app/services/auth/permission.service';
import { sortByAttrAsc, sortByExtractedValue } from 'app/services/utils.service';
import { Role } from 'app/services/auth/role.constant';
import { Subscription } from 'rxjs';
import { Event } from 'app/entities/event/event.types';

export type EventCardButtons = {
  results?: boolean;
  register?: boolean;
  registerJudges?: boolean;
  detailedInfo?: boolean;
};

@Component({
  selector: 'event-card',
  templateUrl: 'event-card.html',
})
export class EventCardComponent implements OnInit, OnDestroy {
  @Input() event: Event;
  @Input() buttons: EventCardButtons;
  @Input() showCategoriesOnMobile: boolean;
  registrationPhase2StartDate: Date;
  categories: any[] = [];
  categoriesWithResults: any[] = [];
  categoriesWithInternalResults: any[] = [];
  categoriesWithExternalResults: any[] = [];
  sportWithResultsCount: number;
  showResults: boolean;
  showRegisterJudges: boolean;
  imageContainerStyle: object;
  categoriesCountBySportType: { [sportType: string]: number };
  toDetailedInfo: boolean;
  showRegisterDefault: boolean;
  showRegisterWithOptions: boolean;
  private subscription: Subscription;

  constructor(
    private sportService: SportService,
    private permissionService: PermissionService,
    private principalService: PrincipalService
  ) {}

  ngOnInit() {
    this.buttons = this.buttons || {};
    if (this.hasSecondPhaseRegistration()) {
      this.registrationPhase2StartDate = new Date(this.event.registrationEndDate1.getTime());
      this.registrationPhase2StartDate.setDate(this.registrationPhase2StartDate.getDate() + 1);
    }

    this.categories = this.event.categories.filter((c) => c.type === 'DEFAULT');
    this.categories.sort(sortByAttrAsc('sportType'));

    this.categoriesWithResults = this.sportService.categoriesWithResults(this.event.categories);
    this.categoriesWithInternalResults = sortByExtractedValue(
      this.sportService.categoriesWithInternalResults(this.event.categories),
      (c) => c.sportType + ':' + c.name,
      true
    );
    this.categoriesWithExternalResults = sortByExtractedValue(
      this.sportService.categoriesWithExternalResults(this.event.categories),
      (c) => c.sportType + ':' + c.name,
      true
    );
    this.sportWithResultsCount = this.categoriesWithResults.reduce((sportTypes, c) => {
      if (!sportTypes.includes(c.sportType)) {
        sportTypes.push(c.sportType);
      }
      return sportTypes;
    }, []).length;

    this.showResults =
      this.buttons.results !== undefined
        ? this.buttons.results
        : this.eventStarted() && this.categoriesWithResults.length > 0;
    this.showRegisterJudges = this.buttons.registerJudges !== undefined ? this.buttons.registerJudges : false;
    this.toDetailedInfo = this.buttons.detailedInfo;

    this.imageContainerStyle = {
      'background-image': "url('" + this.selectBgImageUrl() + "')",
    };

    this.categoriesCountBySportType = this.categories.reduce(function (acc, category) {
      if (!acc[category.sportType]) {
        acc[category.sportType] = 0;
      }
      acc[category.sportType]++;
      return acc;
    }, {});

    this.subscription = this.principalService.getCurrentIdentity().subscribe({
      next: () => this.defineButtonsVisibility(),
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  openExtCategoryResults() {
    window.open(
      'https://live.sporteventsystems.se/score/?country=fin&year=' + this.event.startDate.getFullYear(),
      '_blank'
    );
  }

  defineButtonsVisibility() {
    const showRegister =
      this.buttons.register ??
      ((this.registrationActive() && this.hasRegistrationRights()) ||
        this.permissionService.isFedUser() ||
        (this.registrationStarted() && this.permissionService.isEventAdmin(this.event)));
    const externalRegistrationsEnabled = this.event.settings?.externalRegistrationsEnabled;
    if (externalRegistrationsEnabled) {
      this.showRegisterDefault = showRegister && this.principalService.isAuthenticated();
      this.showRegisterWithOptions = showRegister && !this.principalService.isAuthenticated();
    } else {
      this.showRegisterDefault = showRegister;
      this.showRegisterWithOptions = false;
    }
    this.showRegisterJudges =
      this.buttons.register !== undefined ? this.buttons.registerJudges : this.hasJudgeRegistrationRights();
  }

  eventStarted() {
    return this.event.startDate < new Date();
  }

  registrationActive() {
    if (!this.event.registrationStartDate || !this.event.registrationEndDate1 || !this.event.registrationEndDate2) {
      return false;
    }
    var now = new Date();
    var finalRegEndDate = new Date(this.event.registrationEndDate2.getTime());
    finalRegEndDate.setDate(finalRegEndDate.getDate() + 1);
    return now > this.event.registrationStartDate && now < finalRegEndDate;
  }

  registrationStarted() {
    if (!this.event.registrationStartDate || !this.event.registrationEndDate1 || !this.event.registrationEndDate2) {
      return false;
    }
    return new Date() > this.event.registrationStartDate;
  }

  hasJudgeRegistrationRights() {
    if (!this.principalService.isAuthenticated()) {
      return false;
    }
    return (
      this.principalService.hasAnyAuthority(Role.CLUB_USER_ROLES) ||
      this.principalService.hasAnyAuthority(Role.FED_ROLES)
    );
  }

  hasRegistrationRights() {
    if (!this.principalService.isAuthenticated()) {
      return true;
    }
    return this.principalService.hasAnyAuthority(Role.CLUB_USER_ROLES);
  }

  isMultiDay() {
    return this.event.startDate.getTime() !== this.event.endDate.getTime();
  }

  hasSecondPhaseRegistration() {
    if (this.event.registrationEndDate1 && this.event.registrationEndDate2) {
      return this.event.registrationEndDate1.getTime() !== this.event.registrationEndDate2.getTime();
    }
    return false;
  }

  selectBgImageUrl() {
    if (this.event.categories.length > 0) {
      var sportType = this.event.categories[0].sportType;
      switch (sportType) {
        case 'RHYTHMIC_GYMNASTICS':
          return 'https://kisanet-static-content.fra1.cdn.digitaloceanspaces.com/event-logo/rg-event-logo-1.jpg';
        case 'AGG':
          return 'https://kisanet-static-content.fra1.cdn.digitaloceanspaces.com/event-logo/agg-event-logo-1.jpg';
        case 'WAG':
          return 'https://kisanet-static-content.fra1.cdn.digitaloceanspaces.com/event-logo/ntv-event-logo-1.jpg';
        case 'MAG':
          return 'https://kisanet-static-content.fra1.cdn.digitaloceanspaces.com/event-logo/mtv-event-logo-2.jpg';
        case 'TUM':
          return 'https://kisanet-static-content.fra1.cdn.digitaloceanspaces.com/event-logo/tum-event-logo-1.jpg';
        case 'AKRO':
          return 'https://kisanet-static-content.fra1.cdn.digitaloceanspaces.com/event-logo/akro-event-logo-1.jpg';
        case 'TANVO':
          return 'https://kisanet-static-content.fra1.cdn.digitaloceanspaces.com/event-logo/tanvo-event-logo-1.jpg';
        case 'LUMO':
          return 'https://kisanet-static-content.fra1.cdn.digitaloceanspaces.com/event-logo/lumo-event-logo-1.jpg';
        case 'TG':
          return 'https://kisanet-static-content.fra1.cdn.digitaloceanspaces.com/event-logo/tg-event-logo-1.jpg';
        case 'KA':
          return 'https://kisanet-static-content.fra1.cdn.digitaloceanspaces.com/event-logo/ka-event-logo-1.jpg';
        case 'STARA':
          return 'https://kisanet-static-content.fra1.cdn.digitaloceanspaces.com/event-logo/stara-event-logo-2.jpg';
        case 'GFL':
          return 'https://kisanet-static-content.fra1.cdn.digitaloceanspaces.com/event-logo/gfl-event-logo-1.jpg';
        case 'DANCE_LUMO':
          return 'https://kisanet-static-content.fra1.cdn.digitaloceanspaces.com/event-logo/dance-lumo-event-logo-1.jpg';
        case 'TUL_DANCE':
          return 'https://kisanet-static-content.fra1.cdn.digitaloceanspaces.com/event-logo/tul-dance-event-logo-1.jpg';
      }
    }
    return 'images/event-logo/event.jpg';
  }
}
