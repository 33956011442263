import { Injectable } from '@angular/core';
import { PrincipalService } from 'app/services/auth/principal.service';
import { ContextService } from 'app/services/context.service';
import { Role } from 'app/services/auth/role.constant';

export interface IPermissionService {
  isEventOrganizerUser(event): boolean;
  isEventAdmin(event): boolean;
  hasAdminAccessToEvent(event): boolean;
  hasAuthorityInOrg(authority: string, orgId: number): boolean;
  hasAuthorityInOrg(authority: string, orgId: number[]): boolean;
  hasAuthorityInOrg(authority: string[], orgId: number): boolean;
  hasAuthorityInOrg(authority: string[], orgId: number[]): boolean;
  isFedUser(): boolean;
}

export interface IPermission extends IPermissionService {}

@Injectable({ providedIn: 'root' })
export class PermissionService implements IPermissionService {
  constructor(private principalService: PrincipalService, private contextService: ContextService) {}

  public isEventOrganizerUser(event) {
    const eventOrganizerId = this.getEventOrganizerId(event);
    const currentUserOrgId = this.principalService.getCurrentUserOrgId();
    return eventOrganizerId === currentUserOrgId;
  }

  public hasAuthorityInOrg(authority: string, orgId: number): boolean;
  public hasAuthorityInOrg(authority: string, orgId: number[]): boolean;
  public hasAuthorityInOrg(authority: string[], orgId: number): boolean;
  public hasAuthorityInOrg(authority: string[], orgId: number[]): boolean;
  public hasAuthorityInOrg(authority: any, orgId: any): boolean {
    const currentUserOrgId = this.principalService.getCurrentUserOrgId();
    const authorities = Array.isArray(authority) ? authority : [authority];
    const hasAuthority = this.principalService.hasAnyAuthority(authorities);

    const organizationIds = Array.isArray(orgId) ? orgId : [orgId];
    const orgMatches: boolean = organizationIds.includes(currentUserOrgId);
    return orgMatches && hasAuthority;
  }

  public hasAdminAccessToEvent(event) {
    return this.isEventAdmin(event) || this.principalService.hasAuthoritySync('ROLE_FED_USER');
  }

  public isFedUser(): boolean {
    return this.principalService.hasAuthoritySync('ROLE_FED_USER');
  }

  public isEventAdmin(event): boolean {
    return (
      (this.isEventOrganizerUser(event) && this.principalService.hasAnyAuthority(Role.CLUB_EVENT_ADMIN_ROLES)) ||
      this.isFedUser()
    );
  }

  private getEventOrganizerId(event) {
    const currentEvent = event || this.contextService.get('event');
    return currentEvent && currentEvent.organizer ? currentEvent.organizer.id : null;
  }
}

// legacyComponents.factory('Permission', Permission);

Permission.$inject = ['Principal', 'Role', 'Context'];

function Permission(Principal, Role, Context) {
  const service: IPermission = {
    isEventAdmin: isEventAdmin,
    isEventOrganizerUser: isEventOrganizerUser,
    hasAdminAccessToEvent: hasAdminAccessToEvent,
    hasAuthorityInOrg: hasAuthorityInOrg,
    isFedUser: isFedUser,
  };

  function hasAdminAccessToEvent(event) {
    return isEventAdmin(event) || isFedUser();
  }

  function isFedUser(): boolean {
    return Principal.hasAuthoritySync('ROLE_FED_USER');
  }

  function isEventAdmin(event): boolean {
    return isEventOrganizerUser(event) && Principal.hasAnyAuthority(Role.CLUB_EVENT_ADMIN_ROLES);
  }

  function hasAuthorityInOrg(authority: string, orgId: number): boolean;
  function hasAuthorityInOrg(authority: string, orgId: number[]): boolean;
  function hasAuthorityInOrg(authority: string[], orgId: number): boolean;
  function hasAuthorityInOrg(authority: string[], orgId: number[]): boolean;
  function hasAuthorityInOrg(authority: any, orgId: any): boolean {
    const currentUserOrgId = Principal.getCurrentUserOrgId();
    const authorities = Array.isArray(authority) ? authority : [authority];
    const hasAuthority = Principal.hasAnyAuthority(authorities);

    const organizationIds = Array.isArray(orgId) ? orgId : [orgId];
    const orgMatches: boolean = organizationIds.includes(currentUserOrgId);
    return orgMatches && hasAuthority;
  }

  function getEventOrganizerId(event) {
    const currentEvent = event || Context.get('event');
    return currentEvent && currentEvent.organizer ? currentEvent.organizer.id : null;
  }

  function isEventOrganizerUser(event) {
    const eventOrganizerId = getEventOrganizerId(event);
    const currentUserOrgId = Principal.getCurrentUserOrgId();
    return eventOrganizerId === currentUserOrgId;
  }

  return service;
}
