import { PageRequest } from 'app/model/page-request';
import { AbstractPaginatedLoader } from 'app/model/paginated-loader.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import {
  convertDateTimeFromServer,
  convertLocalDateFromServer,
  convertLocalDateToServer,
  isBetweenDays,
} from 'app/components/util/date-util.service';
import { IPage } from 'app/model/page';
import {
  transformStartListBlockFromServerJson,
  transformStartListFromServer,
} from 'app/entities/start-list/start-list.service';
import { toHttpParams } from 'app/services/utils.service';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CreateJudgeRegistrationDto,
  JudgeRegistration,
  judgeRegistrationSchema,
} from 'app/entities/judge-registration/judge-registration.types';
import Bugsnag from '@bugsnag/js';
import { Event, EventSchema, EventToServer, EventToServerSchema } from 'app/entities/event/event.types';
import { StartListInfo, StartListInfoArraySchema } from 'app/entities/start-list/start-list-info.types';
import {
  CreateStartListRequest,
  PersistedBlockSpecV2,
  StartListBlock,
} from 'app/entities/event/start-list/start-list.types';
import { EventReport } from 'app/entities/event/event-report.types';

export class EventSearchRequest extends PageRequest {
  readonly searchString?: string;
  readonly sportId?: string[];

  constructor(page: number, size: number, sort: string[], searchString?: string, sportId?: string[]) {
    super(page, size, sort);
    this.searchString = searchString;
    this.sportId = sportId;
  }

  toHttpParams() {
    let params = new HttpParams();
    params = params.set('page', this.page.toString()).set('size', this.size.toString());
    this.sort.forEach((s) => {
      params = params.append('sort', s);
    });
    if (this.searchString) {
      params = params.set('searchString', this.searchString);
    }
    if (this.sportId?.length) {
      this.sportId.forEach((id) => {
        params = params.append('sportId', id);
      });
    }
    return params;
  }
}

export type CreateStartListBlockRequest = {
  startListId: number | null;
  blockId: number | null;
  startListType: string;
  blockSpec: PersistedBlockSpecV2;
  excludeParticipantIds: number[];
  placeholderNamePrefix: string | null;
};

export abstract class AbstractEventLoader extends AbstractPaginatedLoader<
  Event,
  EventSearchRequest,
  EventSearchParameters
> {
  protected constructor(objects: Event[], pageSize?: number) {
    super(objects, pageSize);
  }

  protected createSearchRequest(pageToLoad: number, pageSize: number, sort: string[]): EventSearchRequest {
    return new EventSearchRequest(
      pageToLoad,
      pageSize,
      sort || ['id,ASC'],
      this.searchData?.query,
      this.searchData?.sportIds
    );
  }

  loadFromState(
    queryString: string,
    sportIds: string[],
    loadedPage: number,
    sort: string[] = ['id,ASC']
  ): Promise<void> {
    this.resetState();
    this.searchData = new EventSearchParameters(queryString, sportIds && sportIds.slice());
    this.sort = sort;
    return this.loadCustomPage((loadedPage + 1) * this.pageSize, loadedPage);
  }
}

export class FutureEventLoader extends AbstractEventLoader {
  constructor(private eventService: EventServiceNg2, events: any[], pageSize: number = 10) {
    super(events, pageSize);
  }

  load(searchData: EventSearchParameters, sort: string[] = ['startDate,ASC', 'id,ASC']): Promise<void> {
    return super.load(searchData, sort);
  }

  loadFromState(
    queryString: string,
    sportIds: string[],
    loadedPage: number,
    sort: string[] = ['startDate,ASC', 'id,ASC']
  ): Promise<void> {
    return super.loadFromState(queryString, sportIds, loadedPage, sort);
  }

  protected doLoad(request: EventSearchRequest): Promise<IPage<Event>> {
    return this.eventService.getFuture(request);
  }
}

export class EventSearchParameters {
  readonly query?: string;
  readonly sportIds: string[];

  constructor(query?: string | undefined, sportIds: string[] = []) {
    this.query = query;
    this.sportIds = sportIds;
  }
}

export class PastEventLoader extends AbstractEventLoader {
  constructor(private eventService: EventServiceNg2, events: Event[], pageSize: number = 10) {
    super(events, pageSize);
  }

  load(searchData: EventSearchParameters, sort: string[] = ['startDate,DESC', 'id,ASC']): Promise<void> {
    return super.load(searchData, sort);
  }

  loadFromState(
    queryString: string,
    sportIds: string[],
    loadedPage: number,
    sort: string[] = ['startDate,DESC', 'id,ASC']
  ): Promise<void> {
    return super.loadFromState(queryString, sportIds, loadedPage, sort);
  }

  protected doLoad(request: EventSearchRequest): Promise<IPage<Event>> {
    return this.eventService.getPast(request);
  }
}

export class OwnFutureEventLoader extends AbstractEventLoader {
  constructor(private eventService: EventServiceNg2, events: any[], pageSize: number) {
    super(events, pageSize);
  }

  load(searchData: EventSearchParameters, sort: string[] = ['startDate,ASC', 'id,ASC']): Promise<void> {
    return super.load(searchData, sort);
  }

  loadFromState(
    queryString: string,
    sportIds: string[],
    loadedPage: number,
    sort: string[] = ['startDate,ASC', 'id,ASC']
  ): Promise<void> {
    return super.loadFromState(queryString, sportIds, loadedPage, sort);
  }

  protected doLoad(request: EventSearchRequest): Promise<IPage<Event>> {
    return this.eventService.getOwnFuture();
  }

  protected createSearchRequest(pageToLoad: number, pageSize: number, sort: string[]): EventSearchRequest {
    return new EventSearchRequest(pageToLoad, pageSize, ['startDate,ASC', 'id,ASC'], undefined, undefined);
  }
}

export class OwnPastEventLoader extends AbstractEventLoader {
  constructor(private eventService: EventServiceNg2, events: any[], pageSize?: number) {
    super(events, pageSize);
  }

  load(searchData: EventSearchParameters, sort: string[] = ['startDate,DESC', 'id,ASC']): Promise<void> {
    return super.load(searchData, sort);
  }

  loadFromState(
    queryString: string,
    sportIds: string[],
    loadedPage: number,
    sort: string[] = ['startDate,DESC', 'id,ASC']
  ): Promise<void> {
    return super.loadFromState(queryString, sportIds, loadedPage, sort);
  }

  protected doLoad(request: EventSearchRequest): Promise<IPage<Event>> {
    return this.eventService.getOwnPast();
  }

  protected createSearchRequest(pageToLoad: number, pageSize: number, sort: string[]): EventSearchRequest {
    return new EventSearchRequest(pageToLoad, pageSize, ['startDate,DESC', 'id,ASC'], undefined, undefined);
  }
}

@Injectable()
export class EventServiceNg2 {
  constructor(private http: HttpClient) {}

  async getPublic(id: number): Promise<Event> {
    return this.http
      .get(`api/public/events/${id}`)
      .toPromise()
      .then((event) => transformEventData(event));
  }

  getLive() {
    return this.http
      .get(`api/public/events/live`)
      .toPromise()
      .then((events) => transformEventsData(events));
  }

  getFuture(request: EventSearchRequest): Promise<IPage<Event>> {
    return this.http
      .get(`api/public/events/future`, { observe: 'response', params: request.toHttpParams() })
      .toPromise()
      .then((response) => this.toEventPage(response));
  }

  getPast(request: EventSearchRequest): Promise<IPage<Event>> {
    return this.http
      .get(`api/public/events/past`, { observe: 'response', params: request.toHttpParams() })
      .toPromise()
      .then((response) => this.toEventPage(response));
  }

  getOwnFuture(): Promise<IPage<Event>> {
    return this.http
      .get(`api/events/own?ended=false`, { observe: 'response' })
      .toPromise()
      .then((response) => this.toEventPage(response));
  }

  getOwnPast(): Promise<IPage<Event>> {
    return this.http
      .get(`api/events/own?ended=true`, { observe: 'response' })
      .toPromise()
      .then((response) => this.toEventPage(response));
  }

  getReport(eventId: number, onlyDefaultRound: boolean = false): Promise<EventReport> {
    const params = new HttpParams().set('onlyDefaultRound', onlyDefaultRound.toString());
    return this.http.get<EventReport>(`api/public/events/${eventId}/report`, { params }).toPromise();
  }

  private toEventPage(response: HttpResponse<any>): IPage<Event> {
    const totalCount = parseInt(response.headers.get('X-Total-Count') ?? '0', 10);
    const events: Event[] = response.body as Event[];
    return {
      page: 0,
      totalCount,
      content: transformEventsData(events),
    };
  }

  saveStartList(eventId: number, startList: CreateStartListRequest): Promise<any> {
    const params = toHttpParams(_.pick(startList, ['slType', 'placeholderNamePrefix']));
    return this.http.post(`api/events/${eventId}/startlist`, startList, { params }).toPromise();
  }

  saveAgStartList(eventId: number, startList): Promise<any> {
    return this.http.post(`api/events/${eventId}/agstartlist`, startList).toPromise();
  }

  getNews(eventId: number): Promise<any[]> {
    return this.http
      .get(`api/public/events/${eventId}/news`)
      .toPromise()
      .then((itemsJson) => itemsJson as any[]);
  }

  saveNewsItem(eventId: number, data: any) {
    return this.http.post(`api/events/${eventId}/news`, data).toPromise();
  }

  deleteNewsItem(newsItemId: number): Promise<any> {
    return this.http.delete(`api/events/news/${newsItemId}`).toPromise();
  }

  registerJudge(eventId: number, reg: CreateJudgeRegistrationDto): Promise<JudgeRegistration> {
    const body = {
      ...reg,
      blockedDays: reg.blockedDays.map(convertLocalDateToServer),
    };
    return this.http.post<JudgeRegistration>(`api/events/${eventId}/judges`, body).toPromise();
  }

  getPublicStartLists(eventId: number): Promise<StartListInfo[]> {
    return this.http
      .get(`api/public/events/${eventId}/startlists`)
      .toPromise()
      .then(parseStartListInfoFromServer)
      .then((startLists) => _.orderBy(startLists, ['startTime', 'name'], ['asc', 'asc']));
  }

  getStartLists(eventId: number): Promise<StartListInfo[]> {
    return this.http
      .get(`api/events/${eventId}/startlists`)
      .toPromise()
      .then(parseStartListInfoFromServer)
      .then((startLists) => _.orderBy(startLists, ['startTime', 'name'], ['asc', 'asc']));
  }

  save(event): Promise<Event> {
    return this.http.post<Event>(`api/events`, transformEventToServer(event)).toPromise();
  }

  update(event: Event): Promise<Event> {
    return this.http.put<Event>(`api/events`, transformEventToServer(event)).toPromise();
  }

  updatePlanned(event): Promise<Event> {
    return this.http.put<Event>(`api/events/planned`, transformEventToServer(event)).toPromise();
  }

  delete(eventId) {
    return this.http.delete(`api/events/${eventId}`).toPromise();
  }

  getOnePlanned(eventId: number): Promise<Event> {
    return this.http
      .get(`api/events/planned/${eventId}`)
      .toPromise()
      .then((event) => transformEventData(event));
  }

  assignClub(eventId: number, club: any): Promise<Event> {
    return this.http.patch<Event>(`api/events/${eventId}`, club).toPromise();
  }

  registerPerformance(eventId: number, registration: any): Promise<any> {
    return this.http.post(`api/events/${eventId}/performances`, registration).toPromise();
  }

  getJudges(eventId: number): Promise<JudgeRegistration[]> {
    return this.http
      .get<JudgeRegistration[]>(`api/events/${eventId}/judges`)
      .toPromise()
      .then((judges) => {
        try {
          return judges.map((jr) => judgeRegistrationSchema.parse(jr)) as JudgeRegistration[];
        } catch (e) {
          console.error('Failed to parse judge registrations', e);
          Bugsnag.notify(e);
          judges.forEach((judge) => {
            judge.blockedDays = judge.blockedDays.map(convertLocalDateFromServer);
            judge.registrationDate = convertDateTimeFromServer(judge.registrationDate);
          });
          return judges;
        }
      });
  }

  getJudgePanels(eventId: number): Promise<any[]> {
    return this.http.get<any[]>(`api/events/${eventId}/judgePanels`).toPromise();
  }

  getCancelledRegistrationIds(eventId: number): Promise<number[]> {
    return this.http
      .get<any[]>(`api/events/${eventId}/registrations/cancelled`)
      .toPromise()
      .then((registrations) => registrations.map((reg) => reg.id));
  }

  generateStartList(eventId: number, startListSpec): Promise<any> {
    return this.http
      .post(`api/events/${eventId}/startListDraft`, startListSpec)
      .toPromise()
      .then((startList) => transformStartListFromServer(startList));
  }

  async generateStartListBlock(eventId: number, request: CreateStartListBlockRequest): Promise<StartListBlock> {
    const rawBlock = await this.http.post(`api/events/${eventId}/startListBlockDraft`, request).toPromise();
    return transformStartListBlockFromServerJson(rawBlock);
  }

  findParticipants(eventId: number, query: string, includePerformances: boolean): Promise<any[]> {
    const params = toHttpParams({ query, includePerformances });
    return this.http.get<any[]>(`api/events/${eventId}/participants`, { params }).toPromise();
  }

  search(searchRequest: EventSearchRequest): Observable<IPage<Event>> {
    const params = toHttpParams(searchRequest);
    return this.http
      .get<Event[]>(`api/public/events/search`, { params, observe: 'response' })
      .pipe(map((response) => this.toEventPage(response)));
  }
}
function convertEventDates(event) {
  event.startDate = convertLocalDateFromServer(event.startDate);
  event.endDate = convertLocalDateFromServer(event.endDate);
  event.registrationStartDate = convertDateTimeFromServer(event.registrationStartDate);
  event.registrationEndDate1 = convertLocalDateFromServer(event.registrationEndDate1);
  event.registrationEndDate2 = convertLocalDateFromServer(event.registrationEndDate2);
  return event;
}

function filterPerformanceCategory(event: Event) {
  let perfCategory = event.categories.find((cat) => cat.type === 'PERFORMANCE');
  if (perfCategory) {
    event.performanceCategory = perfCategory;
    event.categories = event.categories.filter((cat) => cat.type !== 'PERFORMANCE');
  }
}

function transformEventsData(data) {
  if (data) {
    const events = typeof data === 'string' ? JSON.parse(data) : data;
    return events.map(transformEventData);
  }
  return data;
}

export function transformEventData(rawEvent: any): Event {
  if (!rawEvent) return rawEvent;
  try {
    const parsedEvent = EventSchema.parse(rawEvent);
    filterPerformanceCategory(parsedEvent);
    return parsedEvent;
  } catch (e) {
    console.error('Failed to parse event', e);
    Bugsnag.notify({
      name: 'Failed to parse event',
      message: JSON.stringify({
        error: e,
        data: rawEvent,
      }),
    });
  }
  convertEventDates(rawEvent);
  filterPerformanceCategory(rawEvent);
  return rawEvent;
}

export const isRegistrationOpen = (event: Event) => {
  if (event.registrationStartDate && event.registrationEndDate2) {
    return isBetweenDays(new Date(), event.registrationStartDate, event.registrationEndDate2);
  }
  return false;
};

export const isRegistrationMainPeriodOpen = (event: Event) => {
  if (event.registrationStartDate && event.registrationEndDate1) {
    return isBetweenDays(new Date(), event.registrationStartDate, event.registrationEndDate1);
  }
  return false;
};

export function eventEnded(event: Event) {
  const now = new Date();
  const eventEndTime = new Date(event.endDate);
  eventEndTime.setDate(eventEndTime.getDate() + 1);
  return now >= eventEndTime;
}

export function isEventToday(event: Event) {
  if (!event.endDate) return false;
  return isBetweenDays(new Date(), event.startDate, event.endDate);
}

function transformStartListsFromServer(data) {
  if (data) {
    data = typeof data === 'string' ? JSON.parse(data) : data;
    data.forEach((sl) => transformStartListFromServer(sl));
  }
  return data;
}

function parseStartListInfoFromServer(data: string | any[] | Object): StartListInfo[] {
  if (data) {
    const dataArray = typeof data === 'string' ? JSON.parse(data) : data;
    try {
      return StartListInfoArraySchema.parse(dataArray);
    } catch (e) {
      console.error('Failed to parse start list info', JSON.stringify(e, null, 2));
      // Bugsnag.notify(e);
      return transformStartListsFromServer(dataArray);
    }
  }
  return [];
}

function transformEventToServer(event: Event): EventToServer {
  try {
    return EventToServerSchema.parse(event);
  } catch (e) {
    console.error('Failed to parse event to server', e);
    Bugsnag.notify(e);
  }
  const copy: any = { ...event };
  copy.startDate = convertLocalDateToServer(copy.startDate);
  copy.endDate = convertLocalDateToServer(copy.endDate);
  copy.registrationEndDate1 = convertLocalDateToServer(copy.registrationEndDate1);
  copy.registrationEndDate2 = convertLocalDateToServer(copy.registrationEndDate2);
  return copy;
}
