<footer class="d-print-none" style="margin-top: 30px">
  <div class="inner">
    <div class="d-flex flex-column align-items-center justify-content-center">
      <div class="contacts">
        <div class="copyright">Kisanet© {{year}}</div>
      </div>
      <div>
        <a class="copyright" [translate]="'global.menu.help'" href="https://docs.kisanet.fi" target="_blank">
          Ohjeet
        </a>
      </div>
      <div class="copyright">
        <span [translate]="'global.support'"></span>:&nbsp;
        <a href="mailto:tuki@kisanet.fi" target="_blank">tuki&#64;kisanet.fi</a>
        <svg-icon icon="copy" *copyToCb="'tuki@kisanet.fi';decorate: false"></svg-icon>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</footer>
